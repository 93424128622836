import { silenceAllConsoleLogs, traverseClassAndRemoveUpdateAutomation } from "@core/utility/env-utils"
import { DevEnv } from "./environment.dev"


export let environment = {
  production: true
}
class ProdEnv extends DevEnv  {


  constructor(){
    super()
    this.app.freeTrialEndsAfter = 1
    this.type = "PROD"
    this.backendServerName0 = "api.windmillcode.com"
    this.firebase.storageImageURL ="https://firebasestorage.googleapis.com/v0/b/windmillcodesite.appspot.com/o/"
    silenceAllConsoleLogs()
    traverseClassAndRemoveUpdateAutomation(this)
    this.firebase.config ={
      ...this.firebase.config,
      authDomain: "windmillcode.com",
    }
  }
}


export let ENV =   new ProdEnv()
